.text-container {
    position: relative;
    display: inline-block;
    z-index: 0; /* Establish a stacking context explicitly */
}

.text,
.text-shadow {
    position: relative;
    z-index: 2;
}

.text-stroke {
    z-index: 1;
    color: transparent;
    pointer-events: none;
}

.text-stroke {
    z-index: 3;
}
