.homepage {
    width: 100vw;
    background: linear-gradient(180deg, #febc11 0%, #febc11 53.5%, #ffdf8c 100%),
        #fff;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    .wrapper {
        display: flex;
        flex-direction: row;
        overflow-y: hidden;
        max-width: 1650px;
    }
    .start {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        width: 600px;
        margin-right: 50px;
        margin-left: -20px;
        margin-top: 50px;
        .title-text {
            width: 200px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        button {
            border-radius: 50px;
            border: 5px solid #000;
            background: linear-gradient(180deg, #ff7474 0%, #ff3a3a 100%),
                linear-gradient(180deg, #ffb800 0%, #ffdf8c 100%);
            box-shadow: -4px 4px 0px 0px #000;
            padding: 30px 60px;
            margin-top: 20px;
        }
        p {
            color: #000;
            text-align: center;
            font-family: Inter;
            font-size: 25px;
            font-style: italic;
            font-weight: 900;
            line-height: 28px; /* 112% */
            max-width: 500px;
        }
    }
}
@media (max-width: 1300px) {
    .homepage {
        height: auto;
        .wrapper {
            flex-direction: column;
        }
        .start {
            margin-left: 15px;
            min-height: 70vh;
            width: 100%;
            margin: 0;
            p {
                display: none;
            }
        }
    }
}
