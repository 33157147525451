.App {
    height: 100dvh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    button:hover {
        cursor: pointer;
        transform: translate(1px, -1px);
        box-shadow: -5px 5px 0px 0px #000 !important;
        filter: brightness(1.2);
    }
    button:active {
        transform: translate(-4px, 4px);
        box-shadow: 0px 0px 0px 0px #000 !important;
        filter: brightness(0.95);
    }
}
