.gamepage-wrapper {
    width: 100vw;
    height: 100dvh;
    background: linear-gradient(
        180deg,
        #00467d 0%,
        #002846 25%,
        #001c31 54%,
        #002e51 100%
    );
    .logo {
        position: absolute;
        img {
            width: 300px;
        }
        .short-logo {
            display: none;
        }
        .logo-text {
            position: absolute;
            top: 15px;
            left: 50px;
            width: 200px;
        }
    }
    .menu-button {
        position: absolute;
        right: 0;
        img {
            width: 300px;
        }
        .icon {
            position: absolute;
            right: 80px;
            top: 17px;
        }
    }
    .game {
        width: calc(100% - 100px);
        height: calc(100% - 200px);
        box-sizing: border-box;
        background-color: rgb(0, 0, 0);
        border-radius: 20px;
        box-shadow: -4px 4px 0px 0px #000;
        border: 5px solid #000;
        position: absolute;
        top: calc(50% - 0px);
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        .timer {
            position: relative;
            top: -91px;
            height: 90px;
            .time {
                position: absolute;
                top: 40%;
                left: 50%;
                transform: translate(-50%, -50%);
                h2 {
                    color: #fff;
                    text-align: center;
                    font-family: Inter;
                    font-size: 40px;
                    font-style: italic;
                    font-weight: 900;
                    line-height: 40px;
                    margin: 0;
                }
            }
            .score {
                position: absolute;
                top: 75%;
                left: 50%;
                transform: translate(-50%, -50%);
                h3 {
                    color: #fff;
                    text-align: center;
                    font-family: Inter;
                    font-size: 20px;
                    font-style: italic;
                    font-weight: 900;
                    line-height: 20px;
                    margin: 0;
                }
            }
        }
        .image-wrapper {
            height: 100%;
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            border-radius: 16px;

            .image {
                background-color: rgb(0, 0, 0);
                height: 100%;
                width: 100%;
                border-radius: 16px;
                overflow: hidden;
            }
            .map {
                background-color: white;
                position: absolute;
                right: 20px;
                bottom: 20px;
                width: 300px;
                height: 300px;
                border-radius: 40px;
                box-shadow: -4px 4px 0px 0px #000;
                border: 5px solid #000;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .map-large {
                width: 80vw;
                height: 80vw;
                max-height: 50vh;
                border-radius: 80vw;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                position: absolute;
                box-shadow: -4px 4px 0px 0px #000;
                border: 5px solid #000;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .progress-bar {
        display: flex;
        position: absolute;
        bottom: 20px;
        left: 50px;
    }
    .submit-wrapper {
        button {
            width: 150px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 50px;
            bottom: 20px;
            border-radius: 50px;
            border: 5px solid #000;
            background: linear-gradient(180deg, #ffb800 0%, #ffdf8c 100%);
            box-shadow: -4px 4px 0px 0px #000;
        }
    }
}

@media (max-width: 1060px) {
    .gamepage-wrapper {
        .logo {
            img {
                width: 225px;
            }
            .logo-text {
                top: 10px;
                width: 100px;
                .full-logo {
                    .stroke-text {
                        font-size: 20px !important;
                        line-height: 20px !important;
                    }
                }
            }
        }
        .menu-button {
            img {
                width: 225px;
            }
            .icon {
                right: 80px;
                top: 12px;
                svg {
                    height: 35px !important;
                }
            }
        }
        .game {
            .timer {
                top: -59px;
                height: 90px;
                img {
                    width: 300px;
                }
                .time {
                    top: 23%;
                    h2 {
                        font-size: 26px;
                    }
                }
                .score {
                    top: 45%;
                    h3 {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

@media (max-width: 817px) {
    .gamepage-wrapper {
        .logo {
            img {
                display: none;
            }
            .full-logo {
                display: none;
            }
            .short-logo {
                display: block;
            }
            .logo-text {
                top: 20px;
                left: 10px;
                width: 100px;
            }
        }
        .menu-button {
            img {
                display: none;
            }
            .icon {
                right: 20px;
                top: 20px;
                svg {
                    height: 30px !important;
                }
            }
        }
        .game {
            width: calc(100vw - 20px); // 10px padding on each side
            height: calc(100dvh - 180px); // 60px top, 100px bottom padding
            top: 60px; // 60px from the top
            left: calc(50%); // 10px divided by 2 because of transform translate
            transform: translate(
                -50%,
                0
            ); // adjusted to only horizontally center
            .timer {
                top: -49px;
                height: 90px;
                img {
                    width: 200px;
                }
                .time {
                    top: 20%;
                    h2 {
                        font-size: 18px;
                    }
                }
                .score {
                    top: 35%;
                    h3 {
                        font-size: 10px;
                    }
                }
            }
        }
        .image-wrapper {
            height: 100%;
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            border-radius: 16px;
            .image {
                background-color: rgb(0, 0, 0);
                height: 100%;
                width: 100%;
                border-radius: 16px;
                overflow: hidden;
            }
            .map {
                width: 40vw !important;
                height: 40vw !important;
                right: 10px !important;
                bottom: 10px !important;
                border-radius: 10px !important;
            }
            .large {
                width: 80vw;
                height: 80vw;
                max-width: 600px;
                max-height: 600px;
                border-radius: 80vw;
                top: calc(50% + 200px);
                left: 50%;
                transform: translate(-50%, -50%);
                position: absolute;
                box-shadow: -4px 4px 0px 0px #000;
                border: 5px solid #000;
                overflow: hidden;
            }
        }
        .progress-bar {
            left: 10px;
            bottom: 58px;
        }
        .submit-wrapper {
            button {
                width: 140px;
                height: 35px;
                right: 50%;
                transform: translate(50%, 0%);
                bottom: 15px;
                .stroke-text {
                    font-size: 20px !important;
                }
            }
        }
    }
}
