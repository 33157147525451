.menu {
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.6);
    z-index: 1;
    position: absolute;

    .menu-box {
        padding: 30px 150px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 20px;
        border: 8px solid #000;
        background: linear-gradient(
                180deg,
                #00467d 0%,
                #002846 25%,
                #001c31 54%,
                #002e51 100%
            ),
            linear-gradient(180deg, #febc11 0%, #febc11 53.5%, #ffdf8c 100%),
            #fff;
        box-shadow: -4px 4px 0px 0px #000;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        .restart {
            width: 180px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50px;
            border: 5px solid #000;
            background: linear-gradient(180deg, #ffb800 0%, #ffdf8c 100%);
            box-shadow: -4px 4px 0px 0px #000;
        }
        .quit {
            width: 180px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50px;
            border: 5px solid #000;
            background: linear-gradient(180deg, #ff3a3a 0%, #ff7474 100%);
            box-shadow: -4px 4px 0px 0px #000;
        }
        .close {
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 20px;
            border: 5px solid #000;
            background: linear-gradient(180deg, #ff3a3a 0%, #ff7474 100%);
            box-shadow: -4px 4px 0px 0px #000;
            position: absolute;
            top: -80px;
            right: -10px;
        }
    }
}

@media (max-width: 817px) {
    .menu {
        .menu-box {
            padding: 30px 60px;
        }
    }
}
