.leaderboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    width: 500px;
    border-radius: 20px;
    border: 5px solid #000;
    box-shadow: -4px 4px 0px 0px #000;
    padding-top: 20px;
    min-height: 100%;
    min-width: 600px;
    overflow-y: scroll;
    box-sizing: border-box;
    margin: 10vh 20px;
    min-height: 100vh;
    padding-bottom: 100px;
    .leaderboard-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        width: 100%;
        .user-list {
            width: 70%;
            .user-score {
                color: #000;
                font-family: Inter;
                font-size: 24px;
                font-style: italic;
                font-weight: 900;
                line-height: 30px; /* 178.571% */
                .wrapper {
                    justify-content: space-between;
                    display: flex;
                    flex-direction: row;
                    .username {
                        color: #000;
                        font-family: Inter;
                        font-size: 24px;
                        font-style: italic;
                        font-weight: 900;
                        line-height: 30px; /* 178.571% */
                    }
                    .score {
                        color: #000;
                        font-family: Inter;
                        font-size: 24px;
                        font-style: italic;
                        font-weight: 900;
                        line-height: 30px; /* 178.571% */
                    }
                }
            }
        }
    }
}
.leaderboard::-webkit-scrollbar {
    display: none;
}

.leaderboard {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

@media (max-width: 1300px) {
    .leaderboard {
        min-height: auto;
        width: 80vw;
        max-width: 700px;
    }
}

@media (max-width: 700px) {
    .leaderboard {
        margin: 10px;
        box-sizing: border-box;
        min-width: 400px;
        .leaderboard-container {
            .user-list {
                font-size: 22px !important;
                line-height: 24px; /* 178.571% */
                .user-score {
                    .wrapper {
                        .username {
                            font-size: 22px !important;
                            line-height: 24px; /* 178.571% */
                        }
                        .score {
                            font-size: 22px;
                            line-height: 24px; /* 178.571% */
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 570px) {
    .leaderboard {
        margin: 10px;
        box-sizing: border-box;
        min-width: 350px;
        .leaderboard-container {
            .user-list {
                .user-score {
                    font-size: 18px;
                    line-height: 20px; /* 178.571% */
                    .wrapper {
                        .username {
                            font-size: 18px !important;
                            line-height: 20px; /* 178.571% */
                        }
                        .score {
                            font-size: 18px;
                            line-height: 20px; /* 178.571% */
                        }
                    }
                }
            }
        }
    }
}
